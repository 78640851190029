"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPECIALTIES = exports.PROFESSIONS = exports.ACADEMIC_RANK = exports.LANGUAGE = exports.EVENT_FOLDER = exports.METHOD_OF_DELIVERY = exports.TRAINER_EVENT_DAY_LESSON_TYPE = exports.PRICING_PLAN_TYPE = exports.TRANSITION_TYPE = exports.TRANSITION_STATUS_TYPE = exports.PAYMENT_WAY = exports.BOOKING_STATUS = exports.TRANSITION_PURPOSE = exports.EPAYMENT_METHOD = exports.PAYMENT_NETWORK = exports.EVENT_TYPE = exports.ZOHO_TEMPLATES = exports.ROLE = exports.ADMIN = exports.SETTINGS_IDS = void 0;
exports.SETTINGS_IDS = {
    tax: { CARD_FEE: 5, SAUDI: 0, NON_SAUDI: 1, TAX_NUMBER: 4 },
    heroImages: { index: 2, events: 3 },
    whatsApp: { id: 7 },
    publishExpense: { id: 8 },
    bellNotifications: { id: 9 },
    generalCoupon: { id: 10 },
    removeUserPassword: { id: 11 },
};
exports.ADMIN = {
    admin: {
        name: 'Infinity Medical Academy',
        nameAr: 'اكادمية انفينيتي الطبية',
        email: 'dina.almihdhar@gmail.com',
        emailInfo: 'info@infinity.edu.sa',
        username: 'dinaalmihdhar',
        password: 'passpass123',
        roleId: 1,
        id: 1,
    },
};
exports.ROLE = {
    admin: { titleEn: 'Admin', titleAr: 'الإدارة', id: 1 },
    trainer: { titleEn: 'Trainer', titleAr: 'مدرب', id: 2 },
    trainee: { titleEn: 'Trainee', titleAr: 'متدرب', id: 3 },
    orgnizer: { titleEn: 'Organizer', titleAr: 'جهة تنظيمية', id: 4 },
    marketer: { titleEn: 'Marketer', titleAr: 'مسوق', id: 5 },
};
exports.ZOHO_TEMPLATES = {
    traineesOnsite: {
        template_key: '2d6f.3ae2f171d247e654.k1.eede6760-44e0-11ee-92c5-525400103106.18a374448d6',
        key_alias: 'traineespaidonsite',
    },
    traineesOnline: {
        template_key: '2d6f.3ae2f171d247e654.k1.500df880-44e0-11ee-92c5-525400103106.18a37403808',
        key_alias: 'traineespaidonline',
    },
    trainersOnsite: {
        template_key: '2d6f.3ae2f171d247e654.k1.ddfd27f0-44e1-11ee-92c5-525400103106.18a374a67ef',
        key_alias: 'trainersonsite',
    },
    trainersOnline: {
        template_key: '2d6f.3ae2f171d247e654.k1.95cb9970-44e2-11ee-92c5-525400103106.18a374f1c87',
        key_alias: 'trainersonline',
    },
    trainerOnsiteAndOnline: {
        template_key: '2d6f.3ae2f171d247e654.k1.4eefd6f0-44e3-11ee-92c5-525400103106.18a3753d9df',
        key_alias: 'traineronsiteandonline',
    },
    signup: {
        template_key: '2d6f.3ae2f171d247e654.k1.9c20b050-8d42-11ee-a31b-525400033811.18c11a05fd5',
        key_alias: 'signupverification',
    },
    forgotPassword: {
        template_key: '2d6f.3ae2f171d247e654.k1.92eb0310-8d41-11ee-a31b-525400033811.18c119995c1',
        key_alias: 'forgotpassword',
    },
    studentCertificate: {
        template_key: '2d6f.3ae2f171d247e654.k1.a78500c0-8d6c-11ee-a31b-525400033811.18c12b3eacc',
        key_alias: 'studentcertificate',
    },
    confirmPayment: {
        template_key: '2d6f.3ae2f171d247e654.k1.5f26b120-5860-11ee-a43f-525400103106.18ab70c2d32',
        key_alias: 'confirmpayment',
    },
    confirmPaymentOnsite: {
        template_key: '2d6f.3ae2f171d247e654.k1.2da70100-936a-11ee-9074-525400ae9113.18c39f60510',
        key_alias: 'confirm-payment-onsite',
    },
    confirmPaymentOnline: {
        template_key: '2d6f.3ae2f171d247e654.k1.5f26b120-5860-11ee-a43f-525400103106.18ab70c2d32',
        key_alias: 'confirmpayment',
    },
    unpaidreminder: {
        template_key: '2d6f.3ae2f171d247e654.k1.10467900-41a4-11ee-8e73-525400103106.18a220c3490',
        key_alias: 'unpaidreminder',
    },
    changeMoD: {
        template_key: '2d6f.3ae2f171d247e654.k1.2031aad0-3e91-11ee-8e73-525400103106.18a0de6edfd',
        key_alias: 'confirmtemplate',
    },
    completeRegistration: {
        template_key: '2d6f.3ae2f171d247e654.k1.95677dc0-3c4d-11ee-8e73-525400103106.189ff10d79c',
        key_alias: 'complete-registration',
    },
    joinGroup: {
        template_key: '2d6f.3ae2f171d247e654.k1.9f9d95f0-3c4c-11ee-8e73-525400103106.189ff0a8ccf',
        key_alias: 'joingroup',
    },
    createUser: {
        template_key: '2d6f.3ae2f171d247e654.k1.73f499e0-9501-11ee-8861-525400ae9113.18c4463227e',
        key_alias: 'create-new-user',
    },
    notifyNewComment: {
        template_key: '2d6f.3ae2f171d247e654.k1.5c6ffaf0-369e-11ef-b251-525400f92481.1906788a01f',
        key_alias: 'newcommentnotify',
    },
};
exports.EVENT_TYPE = {
    course: { titleAr: 'محاضرة', titleEn: 'Lecture', id: 1 },
    conference: { titleAr: 'مؤتمر', titleEn: 'Conference', id: 2 },
    Symposium: { titleAr: 'ندوة', titleEn: 'Symposium', id: 3 },
    workshop: { titleAr: 'ورشة عمل', titleEn: 'Workshop', id: 4 },
    Convention: { titleAr: 'ملتقى', titleEn: 'Convention', id: 5 },
    package: { titleAr: 'حزمة', titleEn: 'Package', id: 6 },
    asynchronous: { titleAr: 'غير متزامن', titleEn: 'Asynchronous', id: 7 },
    recurring: { titleAr: 'دوري', titleEn: 'Recurring', id: 8 },
};
exports.PAYMENT_NETWORK = {
    visa: { id: 1, name: 'visa' },
    mada: { id: 2, name: 'mada' },
    master: { id: 3, name: 'mastercard' },
    amex: { id: 4, name: 'amex' },
    manual: { id: 5, name: 'manual' },
};
exports.EPAYMENT_METHOD = {
    creditcard: { id: 1, name: 'creditcard' },
    credit: { id: 1, name: 'credit' },
    applepay: { id: 2, name: 'applepay' },
    stcpay: { id: 3, name: 'stcpay' },
    manual: { id: 4, name: 'manual' },
};
exports.TRANSITION_PURPOSE = {
    bookingEvent: { titleAr: 'حجز حدث', titleEn: 'Booking Event', id: 1 },
    refundBookingEvent: {
        titleAr: 'استرداد حجز حدث',
        titleEn: 'Booking Event Refund',
        id: 2,
    },
    emergencyRefundBookingEvent: {
        titleAr: '(بدأته الأكاديمية) استرداد حجز حدث',
        titleEn: 'Booking Event Refund (initiated by academy)',
        id: 3,
    },
    trainerPayment: {
        titleAr: 'مدفوعات مدرب',
        titleEn: 'Trainer Payment',
        id: 4,
    },
    marketerPayment: {
        titleAr: 'مدفوعات مسوق',
        titleEn: 'Marketer Payment',
        id: 5,
    },
    organizerPayment: {
        titleAr: 'مدفوعات جهة تنظيمية',
        titleEn: 'Organizer Payment',
        id: 6,
    },
    monthlyExpense: {
        titleAr: 'مصروف شهري',
        titleEn: 'Monthly Expense',
        id: 7,
    },
    yearlyExpense: {
        titleAr: 'مصروف سنوي',
        titleEn: 'Yearly Expense',
        id: 8,
    },
    eventExpense: {
        titleAr: 'مصروف حدث',
        titleEn: 'Event Expense',
        id: 9,
    },
    zoom: {
        titleAr: 'زووم',
        titleEn: 'Zoom',
        id: 10,
    },
    designer: {
        titleAr: 'المصمم',
        titleEn: 'Designer',
        id: 11,
    },
    evaluation: {
        titleAr: 'المقييم',
        titleEn: 'Evaluation',
        id: 12,
    },
    learningHours: {
        titleAr: 'ساعات التعليم',
        titleEn: 'Learning Hours',
        id: 13,
    },
    examination: {
        titleAr: 'دراسة الطلب',
        titleEn: 'Examination of the application',
        id: 14,
    },
    ertka: {
        titleAr: 'ارتقاء',
        titleEn: 'Ertka',
        id: 15,
    },
};
exports.BOOKING_STATUS = {
    unpaid: { titleAr: 'لم يتم الدفع', titleEn: 'Unpaid', id: 1 },
    paid: { titleAr: 'تم الدفع', titleEn: 'Paid', id: 2 },
    refunded: { titleAr: 'تم الإسترجاع', titleEn: 'Refunded', id: 3 },
    cancled: { titleAr: 'ملغي', titleEn: 'Canceled', id: 4 },
    guest: { titleAr: 'ضيف', titleEn: 'Guest', id: 5 },
};
exports.PAYMENT_WAY = {
    cash: { title: 'cash', id: 1 },
    moyasar: { title: 'moyasar', id: 2 },
    internal: { title: 'Internal Transfer', id: 3 },
    bankTransfer: { title: 'Bank Transfer', id: 4 },
    wallet: { title: 'Wallet', id: 5 }, //wallet
};
exports.TRANSITION_STATUS_TYPE = {
    initiated: { titleAr: 'بدأت', titleEn: 'Initiated', id: 1 },
    failed: { titleAr: 'فشلت', titleEn: 'Failed', id: 2 },
    paid: { titleAr: 'تمت', titleEn: 'Paid', id: 3 },
    canceled: { titleAr: 'ملغي', titleEn: 'Canceled', id: 4 },
};
exports.TRANSITION_TYPE = {
    payment: { titleAr: 'دفع', titleEn: 'Payment', id: 1 },
    deposit: { titleAr: 'إيداع', titleEn: 'Deposit', id: 2 },
    internalTransfer: {
        titleAr: 'تحويل داخلي',
        titleEn: 'Internal Transfer',
        id: 3,
    },
    depositAndTransfer: {
        titleAr: 'إيداع و تحويل داخلي',
        titleEn: 'Deposit and Internal Transfer',
        id: 4,
    },
    transferAndPayment: {
        titleAr: 'تحويل داخلي و دفع',
        titleEn: 'Internal Transfer and Payment',
        id: 5,
    },
    walletPayment: {
        titleAr: 'دفع من خلال المحفظة',
        titleEn: 'Pay with Wallet',
        id: 6,
    },
};
exports.PRICING_PLAN_TYPE = {
    free: { titleAr: 'مجاني', titleEn: 'Free', id: 1 },
    paid: { titleAr: 'مدفوع', titleEn: 'Paid', id: 2 },
    basedOnSpecialty: {
        titleAr: 'مدفوع بحسب التخصص',
        titleEn: 'Based On Specialty',
        id: 3,
    },
    multiPartProgram: {
        titleAr: 'برنامج من عدة اجزاء',
        titleEn: 'Multi-Part Program',
        id: 4,
    },
};
exports.TRAINER_EVENT_DAY_LESSON_TYPE = {
    lesson: { titleAr: 'درس', titleEn: 'Lesson', id: 1 },
    break: { titleAr: 'فترة راحة', titleEn: 'Break', id: 2 },
    introduction: { titleAr: 'المقدمة', titleEn: 'Introduction', id: 3 },
    finale: { titleAr: 'الخاتمة', titleEn: 'Finale', id: 4 },
};
exports.METHOD_OF_DELIVERY = {
    online: { titleAr: 'عبر الإنترنت', titleEn: 'Online', id: 1 },
    onsite: { titleAr: 'في قاعة', titleEn: 'Onsite', id: 2 },
    onsiteOrOnline: {
        titleAr: 'في قاعة أو عبر الإنترنت',
        titleEn: 'Onsite or Online',
        id: 3,
    },
    onsiteAndOnline: {
        titleAr: 'في قاعة و عبر الإنترنت',
        titleEn: 'Onsite and Online',
        id: 4,
    },
};
exports.EVENT_FOLDER = {
    draft: { titleAr: 'مسودة', titleEn: 'Draft', id: 1 },
    published: { titleAr: 'نشرت', titleEn: 'Published', id: 2 },
    archive: { titleAr: 'مؤرشفة', titleEn: 'Archive', id: 3 },
};
exports.LANGUAGE = {
    English: { title: 'English', id: 1 },
    Arabic: { title: 'العربية', id: 2 },
};
exports.ACADEMIC_RANK = {
    Professor: { titleAr: 'أستاذ', titleEn: 'Professor', id: 1 },
    AssociateProfessor: {
        titleAr: 'أستاذ مشارك',
        titleEn: 'Associate Professor.',
        id: 2,
    },
    AssistantProfessor: {
        titleAr: 'أستاذ مساعد',
        titleEn: 'Assistant Professor.',
        id: 3,
    },
    Teacher: { titleAr: 'مدرس', titleEn: 'Teacher', id: 4 },
    AssistantTeacher: {
        titleAr: 'مدرس مساعد',
        titleEn: 'Assistant Teacher',
        id: 5,
    },
    Other: {
        titleAr: 'أخرى',
        titleEn: 'Other',
        id: 6,
    },
};
exports.PROFESSIONS = [
    { id: 1, sortOrder: 1, titleEn: 'Student/Intern', titleAr: 'Student/Intern' },
    {
        id: 2,
        sortOrder: 2,
        titleEn: 'Resident/Fellow',
        titleAr: 'Resident/Fellow',
    },
    { id: 3, sortOrder: 3, titleEn: 'Physician', titleAr: 'Physician' },
    { id: 4, sortOrder: 4, titleEn: 'Dentistry', titleAr: 'Dentistry' },
    {
        id: 5,
        sortOrder: 5,
        titleEn: 'Dental Technology and Assistants',
        titleAr: 'Dental Technology and Assistants',
    },
    {
        id: 6,
        sortOrder: 6,
        titleEn: 'Clinical Pharmacy',
        titleAr: 'Clinical Pharmacy',
    },
    { id: 7, sortOrder: 7, titleEn: 'Pharmacy', titleAr: 'Pharmacy' },
    {
        id: 8,
        sortOrder: 8,
        titleEn: 'Nursing and Midwifery',
        titleAr: 'Nursing and Midwifery',
    },
    { id: 9, sortOrder: 9, titleEn: 'Nutrition', titleAr: 'Nutrition' },
    {
        id: 10,
        sortOrder: 10,
        titleEn: 'Laboratory Science',
        titleAr: 'Laboratory Science',
    },
    {
        id: 11,
        sortOrder: 11,
        titleEn: 'Science/Assistant',
        titleAr: 'Science/Assistant',
    },
    { id: 12, sortOrder: 12, titleEn: 'Radiological', titleAr: 'Radiological' },
    {
        id: 13,
        sortOrder: 13,
        titleEn: 'Medical Technology',
        titleAr: 'Medical Technology',
    },
    { id: 14, sortOrder: 14, titleEn: 'Physiotherapy', titleAr: 'Physiotherapy' },
    {
        id: 15,
        sortOrder: 15,
        titleEn: 'Respiratory Therapy',
        titleAr: 'Respiratory Therapy',
    },
    {
        id: 16,
        sortOrder: 16,
        titleEn: 'Occupational Therapy',
        titleAr: 'Occupational Therapy',
    },
    {
        id: 17,
        sortOrder: 17,
        titleEn: 'Social Service',
        titleAr: 'Social Service',
    },
    {
        id: 18,
        sortOrder: 18,
        titleEn: 'Emergency Medical Services',
        titleAr: 'Emergency Medical Services',
    },
    {
        id: 19,
        sortOrder: 19,
        titleEn: 'Healthcare & Hospital',
        titleAr: 'Healthcare & Hospital',
    },
    {
        id: 20,
        sortOrder: 20,
        titleEn: 'Health Informatics',
        titleAr: 'Health Informatics',
    },
    {
        id: 21,
        sortOrder: 21,
        titleEn: 'Health Education',
        titleAr: 'Health Education',
    },
    {
        id: 22,
        sortOrder: 22,
        titleEn: 'Public and Community Health',
        titleAr: 'Public and Community Health',
    },
    { id: 23, sortOrder: 23, titleEn: 'Audiology', titleAr: 'Audiology' },
    { id: 24, sortOrder: 24, titleEn: 'Optometry', titleAr: 'Optometry' },
    { id: 25, sortOrder: 25, titleEn: 'Podiatry', titleAr: 'Podiatry' },
    {
        id: 26,
        sortOrder: 26,
        titleEn: 'Speech-Language Pathology',
        titleAr: 'Speech-Language Pathology',
    },
    {
        id: 27,
        sortOrder: 27,
        titleEn: 'Medical Education',
        titleAr: 'Medical Education',
    },
    {
        id: 28,
        sortOrder: 28,
        titleEn: 'Cardiac Perfusion',
        titleAr: 'Cardiac Perfusion',
    },
    {
        id: 29,
        sortOrder: 29,
        titleEn: 'Non-Medical Profession',
        titleAr: 'Non-Medical Profession',
    },
    {
        id: 30,
        sortOrder: 17,
        titleEn: 'Psychology',
        titleAr: 'Psychology',
    },
];
const PsychologySPECIALTIES = [
    { professionId: 30, titleEn: 'Addiction (Substance Use)' },
    { professionId: 30, titleEn: 'Adult Development & Aging' },
    { professionId: 30, titleEn: 'Clinical Child Psychology' },
    { professionId: 30, titleEn: 'Clinical Neuropsychology' },
    { professionId: 30, titleEn: 'Clinical Psychology' },
    { professionId: 30, titleEn: 'Cognitive & Behavioral' },
    { professionId: 30, titleEn: 'Counseling Psychology' },
    { professionId: 30, titleEn: 'Exercise & Sports' },
    { professionId: 30, titleEn: 'Family Therapy' },
    { professionId: 30, titleEn: 'Forensic Psychology' },
    { professionId: 30, titleEn: 'General Psychology' },
    { professionId: 30, titleEn: 'Health Service' },
    { professionId: 30, titleEn: 'Mental Retardation & Disability' },
    { professionId: 30, titleEn: 'Prescribing (Medical)' },
    { professionId: 30, titleEn: 'Psychoanalysis' },
    { professionId: 30, titleEn: 'Psychology' },
    { professionId: 30, titleEn: 'Rehabilitation' },
];
const StudentInternSPECIALTIES = [
    { professionId: 1, titleEn: 'Medicine' },
    { professionId: 1, titleEn: 'Dentistry' },
    { professionId: 1, titleEn: 'Dental health' },
    { professionId: 1, titleEn: 'Cardiac Technology' },
    { professionId: 1, titleEn: 'Anesthesia Technician' },
    { professionId: 1, titleEn: 'Radiology' },
    { professionId: 1, titleEn: 'Nuclear Medicine' },
    { professionId: 1, titleEn: 'Medical Physics' },
    { professionId: 1, titleEn: 'Laboratory' },
    { professionId: 1, titleEn: 'Psychology' },
    { professionId: 1, titleEn: 'Social Service' },
    { professionId: 1, titleEn: 'Nutrition' },
    { professionId: 1, titleEn: 'Law' },
    { professionId: 1, titleEn: 'Occupational Therapy' },
    { professionId: 1, titleEn: 'Respiratory Therapy' },
    { professionId: 1, titleEn: 'physical Therapy' },
    { professionId: 1, titleEn: 'Optometry' },
    { professionId: 1, titleEn: 'Biomedical Technology' },
    { professionId: 1, titleEn: 'Public and Community Health' },
    { professionId: 1, titleEn: 'Healthcare & Hospital' },
    { professionId: 1, titleEn: 'Environmental Health' },
    { professionId: 1, titleEn: 'Epidemiology' },
    { professionId: 1, titleEn: 'Emergency Medical Services' },
    { professionId: 1, titleEn: 'Biochemistry' },
    { professionId: 1, titleEn: 'Microbiology' },
    { professionId: 1, titleEn: 'Chemistry' },
    { professionId: 1, titleEn: 'Biology' },
    { professionId: 1, titleEn: 'Teeth health' },
    { professionId: 1, titleEn: 'Prosthodontics' },
    { professionId: 1, titleEn: 'Medical Records' },
    { professionId: 1, titleEn: 'Medical Secretary' },
    { professionId: 1, titleEn: 'Medical Coding' },
    { professionId: 1, titleEn: 'Fitness Trainer' },
    { professionId: 1, titleEn: 'Sterilization' },
    { professionId: 1, titleEn: 'Pharmacy' },
    { professionId: 1, titleEn: 'Applied Medical Science' },
    { professionId: 1, titleEn: 'Nursing' },
    { professionId: 1, titleEn: 'Anatomy & Genetics' },
    { professionId: 1, titleEn: 'Pathology' },
    { professionId: 1, titleEn: 'Pediatrics' },
    { professionId: 1, titleEn: 'pharmacology' },
    { professionId: 1, titleEn: 'physiological science' },
    { professionId: 1, titleEn: 'Clinical Skills' },
    { professionId: 1, titleEn: 'Other' },
];
const ResidentFellowSPECIALTIES = [
    { professionId: 2, titleEn: 'Allergy and Immunology' },
    { professionId: 2, titleEn: 'Anatomy' },
    { professionId: 2, titleEn: 'Anesthesiology' },
    { professionId: 2, titleEn: 'Biochemistry' },
    { professionId: 2, titleEn: 'Cardiology' },
    { professionId: 2, titleEn: 'Critical Care Medicine' },
    { professionId: 2, titleEn: 'Dermatology' },
    { professionId: 2, titleEn: 'Diagnostic Radiology' },
    { professionId: 2, titleEn: 'Emergency Medicine' },
    { professionId: 2, titleEn: 'Endocrinology & Metabolism' },
    { professionId: 2, titleEn: 'Family Medicine' },
    { professionId: 2, titleEn: 'Forensic Medicine' },
    { professionId: 2, titleEn: 'Gastroenterology' },
    { professionId: 2, titleEn: 'General Practitioners' },
    { professionId: 2, titleEn: 'General Surgery' },
    { professionId: 2, titleEn: 'Hematology' },
    { professionId: 2, titleEn: 'Infectious Disease' },
    { professionId: 2, titleEn: 'Internal Medicine' },
    { professionId: 2, titleEn: 'Medical Genetics' },
    { professionId: 2, titleEn: 'Medical Microbiology' },
    { professionId: 2, titleEn: 'Neonatology' },
    { professionId: 2, titleEn: 'Nephrology' },
    { professionId: 2, titleEn: 'Neurology' },
    { professionId: 2, titleEn: 'Neurosurgery' },
    { professionId: 2, titleEn: 'Obstetrics & Gynecology' },
    { professionId: 2, titleEn: 'Occupational Medicine' },
    { professionId: 2, titleEn: 'Oncology' },
    { professionId: 2, titleEn: 'Ophthalmology' },
    { professionId: 2, titleEn: 'Orthopedic Surgery' },
    { professionId: 2, titleEn: 'Other MD' },
    { professionId: 2, titleEn: 'Pathology (Physicians)' },
    { professionId: 2, titleEn: 'Pediatric' },
    { professionId: 2, titleEn: 'Pediatric Neurology' },
    { professionId: 2, titleEn: 'Pediatric Surgery' },
    { professionId: 2, titleEn: 'Physical Medicine & Rehabilitation' },
    { professionId: 2, titleEn: 'Plastic Surgery' },
    { professionId: 2, titleEn: 'Preventive Medicine and Public Health' },
    { professionId: 2, titleEn: 'Psychiatry' },
    { professionId: 2, titleEn: 'Pulmonary Medicine' },
    { professionId: 2, titleEn: 'Radiation Oncology' },
    { professionId: 2, titleEn: 'Radiology' },
    { professionId: 2, titleEn: 'Rheumatology' },
    { professionId: 2, titleEn: 'Rhino-Otolaryngology' },
    { professionId: 2, titleEn: 'Thoracic and Cardiac Surgery' },
    { professionId: 2, titleEn: 'Urology' },
    { professionId: 2, titleEn: 'Pharmacy' },
];
const PhysicianSPECIALTIES = [
    { professionId: 3, titleEn: 'Allergy and Immunology' },
    { professionId: 3, titleEn: 'Anatomy' },
    { professionId: 3, titleEn: 'Anesthesiology' },
    { professionId: 3, titleEn: 'Biochemistry' },
    { professionId: 3, titleEn: 'Cardiology' },
    { professionId: 3, titleEn: 'Critical Care Medicine' },
    { professionId: 3, titleEn: 'Dermatology' },
    { professionId: 3, titleEn: 'Diagnostic Radiology' },
    { professionId: 3, titleEn: 'Emergency Medicine' },
    { professionId: 3, titleEn: 'Endocrinology & Metabolism' },
    { professionId: 3, titleEn: 'Family Medicine' },
    { professionId: 3, titleEn: 'Forensic Medicine' },
    { professionId: 3, titleEn: 'Gastroenterology' },
    { professionId: 3, titleEn: 'General Practitioners' },
    { professionId: 3, titleEn: 'General Surgery' },
    { professionId: 3, titleEn: 'Hematology' },
    { professionId: 3, titleEn: 'Infectious Disease' },
    { professionId: 3, titleEn: 'Internal Medicine' },
    { professionId: 3, titleEn: 'Medical Genetics' },
    { professionId: 3, titleEn: 'Medical Microbiology' },
    { professionId: 3, titleEn: 'Neonatology' },
    { professionId: 3, titleEn: 'Nephrology' },
    { professionId: 3, titleEn: 'Neurology' },
    { professionId: 3, titleEn: 'Neurosurgery' },
    { professionId: 3, titleEn: 'Obstetrics & Gynecology' },
    { professionId: 3, titleEn: 'Occupational Medicine' },
    { professionId: 3, titleEn: 'Oncology' },
    { professionId: 3, titleEn: 'Ophthalmology' },
    { professionId: 3, titleEn: 'Orthopedic Surgery' },
    { professionId: 3, titleEn: 'Other MD' },
    { professionId: 3, titleEn: 'Pathology (Physicians)' },
    { professionId: 3, titleEn: 'Pediatric' },
    { professionId: 3, titleEn: 'Pediatric Neurology' },
    { professionId: 3, titleEn: 'Pediatric Surgery' },
    { professionId: 3, titleEn: 'Physical Medicine & Rehabilitation' },
    { professionId: 3, titleEn: 'Plastic Surgery' },
    { professionId: 3, titleEn: 'Preventive Medicine and Public Health' },
    { professionId: 3, titleEn: 'Psychiatry' },
    { professionId: 3, titleEn: 'Pulmonary Medicine' },
    { professionId: 3, titleEn: 'Radiation Oncology' },
    { professionId: 3, titleEn: 'Radiology' },
    { professionId: 3, titleEn: 'Rheumatology' },
    { professionId: 3, titleEn: 'Rhino-Otolaryngology' },
    { professionId: 3, titleEn: 'Thoracic and Cardiac Surgery' },
    { professionId: 3, titleEn: 'Urology' },
    { professionId: 3, titleEn: 'Pharmacy' },
];
const DentistrySPECIALTIES = [
    { professionId: 4, titleEn: 'Advanced General Dentistry' },
    { professionId: 4, titleEn: 'Aesthetic Dentistry' },
    { professionId: 4, titleEn: 'Community Dentistry' },
    { professionId: 4, titleEn: 'Conservative Dentistry' },
    { professionId: 4, titleEn: 'Cosmetic' },
    { professionId: 4, titleEn: 'Dental Anesthesiology' },
    { professionId: 4, titleEn: 'Dental Esthetics' },
    { professionId: 4, titleEn: 'Dental Implantology' },
    { professionId: 4, titleEn: 'Dental Public Health' },
    { professionId: 4, titleEn: 'Endodontic Microsurgery' },
    { professionId: 4, titleEn: 'Endodontics' },
    { professionId: 4, titleEn: 'Family Dentistry' },
    { professionId: 4, titleEn: 'Forensic Dentistry' },
    { professionId: 4, titleEn: 'General Dentistry' },
    { professionId: 4, titleEn: 'Geriatric Dentistry' },
    { professionId: 4, titleEn: 'Hospital-Based Dentistry' },
    { professionId: 4, titleEn: 'Management of TMJ Disorder' },
    { professionId: 4, titleEn: 'Maxillofacial Prosthodontics' },
    { professionId: 4, titleEn: 'Maxillofacial Surgery' },
    { professionId: 4, titleEn: 'Oral and Maxillofacial Radiology' },
    { professionId: 4, titleEn: 'Oral Biology' },
    { professionId: 4, titleEn: 'Oral Histology' },
    { professionId: 4, titleEn: 'Oral Medicine' },
    { professionId: 4, titleEn: 'Oral Pathology' },
    { professionId: 4, titleEn: 'Oral Radiology' },
    { professionId: 4, titleEn: 'Oral Surgery' },
    { professionId: 4, titleEn: 'Orofacial Pain' },
    { professionId: 4, titleEn: 'Orthodontics' },
    { professionId: 4, titleEn: 'Orthodontics and Dentofacial Orthopedics' },
    { professionId: 4, titleEn: 'Orthognathic and Facial Plastic Surgery' },
    { professionId: 4, titleEn: 'Pediatric Dentistry' },
    { professionId: 4, titleEn: 'Pediatric Oral & Maxillofacial Surgery' },
    { professionId: 4, titleEn: 'Pedodontics' },
    { professionId: 4, titleEn: 'Periodontics' },
    { professionId: 4, titleEn: 'Preventive Dentistry' },
    { professionId: 4, titleEn: 'Prosthodontics' },
    { professionId: 4, titleEn: 'Removable Prosthodontics' },
    { professionId: 4, titleEn: 'Restorative Dentistry' },
    { professionId: 4, titleEn: 'Special Needs Dentistry' },
    { professionId: 4, titleEn: 'Temporomandibular Joint Disorders' },
    { professionId: 4, titleEn: 'TMJ Management' },
];
const DentalTechnologyAndAssistantsSPECIALTIES = [
    { professionId: 5, titleEn: 'Dental Assistant' },
    { professionId: 5, titleEn: 'Dental Hygiene' },
    { professionId: 5, titleEn: 'Fixed Prosthodontics' },
    { professionId: 5, titleEn: 'Removable Prosthodontics' },
];
const ClinicalPharmacySPECIALTIES = [
    { professionId: 6, titleEn: 'Ambulatory Care' },
    { professionId: 6, titleEn: 'Cardiology' },
    { professionId: 6, titleEn: 'Emergency Medicine' },
    { professionId: 6, titleEn: 'General Clinical Pharmacy' },
    { professionId: 6, titleEn: 'Geriatrics' },
    { professionId: 6, titleEn: 'Hematology & BMT' },
    { professionId: 6, titleEn: 'Infectious Diseases' },
    { professionId: 6, titleEn: 'Intensive Care' },
    { professionId: 6, titleEn: 'Internal Medicine' },
    { professionId: 6, titleEn: 'Neonatal Critical Care' },
    { professionId: 6, titleEn: 'Nephrology' },
    { professionId: 6, titleEn: 'Neurology' },
    { professionId: 6, titleEn: 'OBGYN' },
    { professionId: 6, titleEn: 'Oncology' },
    { professionId: 6, titleEn: 'Organ Transplant' },
    { professionId: 6, titleEn: 'Pediatric' },
    { professionId: 6, titleEn: 'Pediatric Intensive Care' },
    { professionId: 6, titleEn: 'Pediatric Oncology' },
    { professionId: 6, titleEn: 'Pharmacoeconomics' },
    { professionId: 6, titleEn: 'Psychiatry' },
    { professionId: 6, titleEn: 'Pulmonary' },
    { professionId: 6, titleEn: 'Surgery' },
    { professionId: 6, titleEn: 'TPN' },
    { professionId: 6, titleEn: 'Transplant' },
];
const PharmacySPECIALTIES = [
    { professionId: 7, titleEn: 'Biopharmaceutics' },
    { professionId: 7, titleEn: 'Forensic Pharmacy' },
    { professionId: 7, titleEn: 'Healthcare Policy and Management' },
    { professionId: 7, titleEn: 'Hospital Pharmacy' },
    { professionId: 7, titleEn: 'Industrial Pharmacy' },
    { professionId: 7, titleEn: 'Medicinal Chemistry & Drug Analysis' },
    { professionId: 7, titleEn: 'Medicine Economics' },
    { professionId: 7, titleEn: 'Nuclear Pharmacy' },
    { professionId: 7, titleEn: 'Pharmaceutical Administration and Outcomes' },
    { professionId: 7, titleEn: 'Pharmaceutical Analysis' },
    { professionId: 7, titleEn: 'Pharmaceutical Care' },
    { professionId: 7, titleEn: 'Pharmaceutical Chemistry' },
    { professionId: 7, titleEn: 'Pharmaceutical Microbiology' },
    { professionId: 7, titleEn: 'Pharmaceutical Sciences' },
    { professionId: 7, titleEn: 'Pharmaceutics' },
    { professionId: 7, titleEn: 'Pharmacogenetics' },
    { professionId: 7, titleEn: 'Pharmacognosy' },
    { professionId: 7, titleEn: 'Pharmacology' },
    { professionId: 7, titleEn: 'Pharmacy' },
    { professionId: 7, titleEn: 'Quality Control of Pharmaceutical' },
    { professionId: 7, titleEn: 'Quality Management of Pharmaceutical Services' },
    { professionId: 7, titleEn: 'Radiopharmaceuticals and PET Radiochemistry' },
    { professionId: 7, titleEn: 'Toxicology' },
];
const NursingAndMidwiferySPECIALTIES = [
    { professionId: 8, titleEn: 'Acute Care' },
    { professionId: 8, titleEn: 'Adult Care Nursing' },
    { professionId: 8, titleEn: 'Adult Critical Care Nursing' },
    { professionId: 8, titleEn: 'Adult Health' },
    { professionId: 8, titleEn: 'Allergy & Immunology' },
    { professionId: 8, titleEn: 'Anesthesia Nursing' },
    { professionId: 8, titleEn: 'Cardiology' },
    { professionId: 8, titleEn: 'Cardiovascular Nursing' },
    { professionId: 8, titleEn: 'Child & Family Health Nursing' },
    { professionId: 8, titleEn: 'Clinical Nurse Specialist' },
    { professionId: 8, titleEn: 'Community Health Nursing' },
    { professionId: 8, titleEn: 'Dermatology' },
    { professionId: 8, titleEn: 'Diabetic Care Nursing' },
    { professionId: 8, titleEn: 'Diabetic Podiatry Care Nursing' },
    { professionId: 8, titleEn: 'Disaster and Emergency Nursing' },
    { professionId: 8, titleEn: 'Emergency Care Nursing' },
    { professionId: 8, titleEn: 'Endocrinology' },
    { professionId: 8, titleEn: 'Family' },
    { professionId: 8, titleEn: 'Forensic Nursing' },
    { professionId: 8, titleEn: 'Gastroenterology' },
    { professionId: 8, titleEn: 'Geriatric Nursing' },
    { professionId: 8, titleEn: 'Gerontology' },
    { professionId: 8, titleEn: 'Hematology' },
    { professionId: 8, titleEn: 'Hemodialysis Nursing' },
    { professionId: 8, titleEn: 'Infectious Disease' },
    { professionId: 8, titleEn: 'Internal Medicine' },
    { professionId: 8, titleEn: 'Legal Nursing' },
    { professionId: 8, titleEn: 'Maternity and Child Health Nursing' },
    { professionId: 8, titleEn: 'Medical and Surgical Nursing' },
    { professionId: 8, titleEn: 'Medical Genetics' },
    { professionId: 8, titleEn: 'Midwifery' },
    { professionId: 8, titleEn: 'Neo/Perinatal' },
    { professionId: 8, titleEn: 'Neonatal Critical Care Nursing' },
    { professionId: 8, titleEn: 'Nephrology' },
    { professionId: 8, titleEn: 'Neurology' },
    { professionId: 8, titleEn: 'Neurosurgery' },
    { professionId: 8, titleEn: 'Nurse Anesthetist' },
    { professionId: 8, titleEn: 'Pediatrics' },
    { professionId: 8, titleEn: 'Primary Health Care' },
    { professionId: 8, titleEn: 'Nursing Administration' },
    { professionId: 8, titleEn: 'Nursing Education' },
    { professionId: 8, titleEn: 'Nursing Informatics' },
    { professionId: 8, titleEn: 'Nursing Leadership' },
    { professionId: 8, titleEn: 'Obstetrics & Gynecology' },
    { professionId: 8, titleEn: 'Obstetrics and Gynecologic Nursing' },
    { professionId: 8, titleEn: 'Occupational Health' },
    { professionId: 8, titleEn: 'Oncology and Palliative Care Nursing' },
    { professionId: 8, titleEn: 'Ophthalmology' },
    { professionId: 8, titleEn: 'Orthopedic Surgery' },
    { professionId: 8, titleEn: 'Otolaryngology (ENT)' },
    { professionId: 8, titleEn: 'Pain Management Nursing' },
    { professionId: 8, titleEn: 'Pathology' },
    { professionId: 8, titleEn: 'Pediatric Nursing' },
    { professionId: 8, titleEn: 'Pediatric Nursing Critical Care' },
    { professionId: 8, titleEn: 'Physical Medicine/Rehab' },
    { professionId: 8, titleEn: 'Plastic Surgery' },
    { professionId: 8, titleEn: 'Pre, Intra and Postoperative Nursing Care' },
    { professionId: 8, titleEn: 'Psych/Mental Health' },
    { professionId: 8, titleEn: 'Psychological and Mental Health Nursing' },
    { professionId: 8, titleEn: 'Public Health Nursing' },
    { professionId: 8, titleEn: 'Pulmonology' },
    { professionId: 8, titleEn: 'Radiation Oncology' },
    { professionId: 8, titleEn: 'Radiology' },
    { professionId: 8, titleEn: 'Registered Nurse' },
    { professionId: 8, titleEn: 'Rheumatology' },
    { professionId: 8, titleEn: 'School Health Nursing' },
    { professionId: 8, titleEn: 'Thoracic Surgery' },
    { professionId: 8, titleEn: 'Urology' },
    { professionId: 8, titleEn: "Women's Health" },
    { professionId: 8, titleEn: 'Wound Care Nursing' },
];
const NutritionSPECIALTIES = [
    { professionId: 9, titleEn: 'Adult Intensive Care Nutrition' },
    { professionId: 9, titleEn: 'Food Science And Technology' },
    { professionId: 9, titleEn: 'Ambulatory Care Nutrition' },
    { professionId: 9, titleEn: 'Cardiology Nutrition' },
    { professionId: 9, titleEn: 'Diabetic Nutrition' },
    { professionId: 9, titleEn: 'Food and Nutrition Services' },
    { professionId: 9, titleEn: 'General Dietitian, Registered' },
    { professionId: 9, titleEn: 'General Nutritionist' },
    { professionId: 9, titleEn: 'Geriatrics Nutrition' },
    { professionId: 9, titleEn: 'Infectious Diseases Nutrition' },
    { professionId: 9, titleEn: 'Internal Medicine Nutrition' },
    { professionId: 9, titleEn: 'Metabolic Disorders Nutrition' },
    { professionId: 9, titleEn: 'Neonatal Critical Care Nutrition' },
    { professionId: 9, titleEn: 'Nephrology Nutrition' },
    { professionId: 9, titleEn: 'OBGYN Nutrition' },
    { professionId: 9, titleEn: 'Oncology Nutrition' },
    { professionId: 9, titleEn: 'Organ Transplant Nutrition' },
    { professionId: 9, titleEn: 'Pediatric Intensive Care Nutrition' },
    { professionId: 9, titleEn: 'Pediatric Oncology Nutrition' },
    { professionId: 9, titleEn: 'Pediatrics Nutrition' },
    { professionId: 9, titleEn: 'Psychiatry Nutrition' },
    { professionId: 9, titleEn: 'Pulmonary Nutrition' },
    { professionId: 9, titleEn: 'Renal Nutrition' },
    { professionId: 9, titleEn: 'Sports Nutrition' },
    { professionId: 9, titleEn: 'Support Nutrition' },
    { professionId: 9, titleEn: 'Surgery Nutrition' },
];
const LaboratoryScienceSPECIALTIES = [
    { professionId: 10, titleEn: 'Blood Bank & Transfusion Medicine' },
    { professionId: 10, titleEn: 'Clinical Biochemistry' },
    {
        professionId: 10,
        titleEn: 'Clinical Biochemistry - Analytical Toxicology',
    },
    {
        professionId: 10,
        titleEn: 'Clinical Biochemistry - Clinical Analytical Chemistry',
    },
    { professionId: 10, titleEn: 'Clinical Biochemistry - Endocrinology' },
    { professionId: 10, titleEn: 'Clinical Biochemistry - Metabolic Disorders' },
    { professionId: 10, titleEn: 'Microbiology Laboratory' },
    { professionId: 10, titleEn: 'Biochemistry Laboratory' },
    { professionId: 10, titleEn: 'Clinical Immunology' },
    { professionId: 10, titleEn: 'Clinical Physiology' },
    { professionId: 10, titleEn: 'Cord Blood Banking' },
    { professionId: 10, titleEn: 'Embryology' },
    { professionId: 10, titleEn: 'Forensic Laboratories' },
    { professionId: 10, titleEn: 'Genetic Counseling' },
    { professionId: 10, titleEn: 'Genetic Engineering' },
    { professionId: 10, titleEn: 'Hematology - Coagulation' },
    { professionId: 10, titleEn: 'Hematology - Hemoglobinopathies' },
    { professionId: 10, titleEn: 'Histopathology & Cytology - Cytopathology' },
    { professionId: 10, titleEn: 'Histopathology & Cytology - Histopathology' },
    { professionId: 10, titleEn: 'Immunology - Histocompatibility' },
    { professionId: 10, titleEn: 'Immunology - Molecular Immunology' },
    {
        professionId: 10,
        titleEn: 'Immunology - Tissue Typing & Organ Transplantation',
    },
    { professionId: 10, titleEn: 'In Vitro Fertilization' },
    {
        professionId: 10,
        titleEn: 'Medical Genetics - Bioinformatics of Clinical Genetics',
    },
    { professionId: 10, titleEn: 'Medical Genetics - Cytogenetics' },
    { professionId: 10, titleEn: 'Medical Genetics - Molecular Genetics' },
    {
        professionId: 10,
        titleEn: 'Medical Genetics - Pre-Implantation Genetic Diagnosis',
    },
    { professionId: 10, titleEn: 'Medical Laboratory Scientist' },
    { professionId: 10, titleEn: 'Medical Laboratory Specialist' },
    { professionId: 10, titleEn: 'Medical Laboratory Technologist' },
    { professionId: 10, titleEn: 'Medical Microbiology' },
    { professionId: 10, titleEn: 'Medical Microbiology - Bacteriology' },
    {
        professionId: 10,
        titleEn: 'Medical Microbiology - Molecular Microbiology',
    },
    { professionId: 10, titleEn: 'Medical Microbiology - Mycology' },
    { professionId: 10, titleEn: 'Medical Microbiology - Parasitology' },
    { professionId: 10, titleEn: 'Medical Microbiology - Virology' },
    { professionId: 10, titleEn: 'Medical Toxicology' },
    { professionId: 10, titleEn: 'Phlebotomy' },
    { professionId: 10, titleEn: 'Stem Cells' },
];
const ScienceAssistantSPECIALTIES = [
    { professionId: 11, titleEn: 'Biology (Genomic & Biotechnology)' },
    { professionId: 11, titleEn: 'Microbiology' },
    { professionId: 11, titleEn: 'Biochemistry' },
    { professionId: 11, titleEn: 'Chemistry' },
    { professionId: 11, titleEn: 'Cardiac Catheterization' },
    { professionId: 11, titleEn: 'Echocardiography' },
    { professionId: 11, titleEn: 'Forensic Technician' },
    { professionId: 11, titleEn: 'Health Insurance' },
    { professionId: 11, titleEn: 'Hemodialysis' },
    { professionId: 11, titleEn: 'Lithotripsy' },
    { professionId: 11, titleEn: 'Medical Coding' },
    { professionId: 11, titleEn: 'Medical Secretary' },
    { professionId: 11, titleEn: 'Medical Sterilization' },
    { professionId: 11, titleEn: 'Medical Supply' },
    { professionId: 11, titleEn: 'Medical Transcription' },
    { professionId: 11, titleEn: 'Neurophysiology' },
    { professionId: 11, titleEn: 'Operation Rooms' },
    { professionId: 11, titleEn: 'Plastering' },
    { professionId: 11, titleEn: 'Medical Records' },
    { professionId: 11, titleEn: 'Anesthesia' },
];
const RadiologicalSPECIALTIES = [
    { professionId: 12, titleEn: 'General Radiological Technology' },
    { professionId: 12, titleEn: 'Magnetic Resonance Technology' },
    { professionId: 12, titleEn: 'Medical Imaging Informatics' },
    { professionId: 12, titleEn: 'Nuclear Medicine' },
    { professionId: 12, titleEn: 'Radiation Dosimetrist' },
    { professionId: 12, titleEn: 'Radiation Protection' },
    { professionId: 12, titleEn: 'Ultrasound' },
];
const MedicalTechnologySPECIALTIES = [
    { professionId: 13, titleEn: 'Anesthesia Technology' },
    { professionId: 13, titleEn: 'Biomedical Technology' },
    { professionId: 13, titleEn: 'Cardiovascular Technology' },
    { professionId: 13, titleEn: 'Health Bioinformatics' },
    { professionId: 13, titleEn: 'Medical Devices' },
    { professionId: 13, titleEn: 'Medical Physics' },
    { professionId: 13, titleEn: 'Medical Physics - Medical Nuclear Physics' },
    { professionId: 13, titleEn: 'Medical Physics - Radiation Protection' },
    { professionId: 13, titleEn: 'Medical Physics - Radiotherapy' },
    { professionId: 13, titleEn: 'Polysomnography' },
    { professionId: 13, titleEn: 'Prosthetics & Orthotics' },
];
const PhysiotherapySPECIALTIES = [
    {
        professionId: 14,
        titleEn: 'Cardiopulmonary or Cardiovascular & Pulmonary',
    },
    { professionId: 14, titleEn: 'Clinical Electrophysiology' },
    { professionId: 14, titleEn: 'Developmental Disabilities' },
    { professionId: 14, titleEn: 'General Physiotherapy' },
    { professionId: 14, titleEn: 'Geriatrics' },
    { professionId: 14, titleEn: 'Manipulative Therapy or Manual Therapy' },
    { professionId: 14, titleEn: 'Musculoskeletal' },
    { professionId: 14, titleEn: 'Neonatology' },
    { professionId: 14, titleEn: 'Neurology' },
    { professionId: 14, titleEn: 'Osteopathy' },
    { professionId: 14, titleEn: 'Pediatrics' },
    { professionId: 14, titleEn: 'Spine or Chiropractic' },
    { professionId: 14, titleEn: 'Sports' },
    { professionId: 14, titleEn: 'Vestibular' },
    { professionId: 14, titleEn: "Women's Health" },
    { professionId: 14, titleEn: 'Wound Care' },
];
const RespiratoryTherapySPECIALTIES = [
    { professionId: 15, titleEn: 'Emergency Room & Trauma' },
    { professionId: 15, titleEn: 'General Respiratory Therapy' },
    { professionId: 15, titleEn: 'Respiratory Assistant' },
    { professionId: 15, titleEn: 'Respiratory Cardiac' },
    { professionId: 15, titleEn: 'Respiratory Care - Adult Critical Care' },
    { professionId: 15, titleEn: 'Respiratory Care - Neonatal' },
    { professionId: 15, titleEn: 'Respiratory Care - Pediatrics Critical Care' },
    { professionId: 15, titleEn: 'Respiratory Education & Discharge Planning' },
    { professionId: 15, titleEn: 'Respiratory General Care' },
    { professionId: 15, titleEn: 'Respiratory Home Care' },
    { professionId: 15, titleEn: 'Respiratory Laboratory' },
    { professionId: 15, titleEn: 'Respiratory Pulmonary Rehabilitation' },
    { professionId: 15, titleEn: 'Respiratory Sleep' },
];
const OccupationalTherapySPECIALTIES = [
    { professionId: 16, titleEn: 'Gerontology Occupational Therapy' },
    { professionId: 16, titleEn: 'Mental Health Occupational Therapy' },
    { professionId: 16, titleEn: 'Neurological Occupational Therapy' },
    { professionId: 16, titleEn: 'Pediatrics Occupational Therapy' },
    { professionId: 16, titleEn: 'Physical Rehabilitation Occupational Therapy' },
];
const SocialServiceSPECIALTIES = [
    { professionId: 17, titleEn: 'Counselor' },
    { professionId: 17, titleEn: 'Marriage & Family Therapist' },
    { professionId: 17, titleEn: 'Social Worker' },
];
const EmergencyMedicalServicesSPECIALTIES = [
    { professionId: 18, titleEn: 'Emergency Medical Services' },
];
const HealthcareHospitalSPECIALTIES = [
    { professionId: 19, titleEn: 'Risk Management' },
    { professionId: 19, titleEn: 'HealthCare Administration' },
    { professionId: 19, titleEn: 'Health Information Management' },
    { professionId: 19, titleEn: 'Hospitals Administration' },
    { professionId: 19, titleEn: 'Quality Management' },
];
const HealthInformaticsSPECIALTIES = [
    { professionId: 20, titleEn: 'Healthcare Information Systems' },
];
const HealthEducationSPECIALTIES = [
    { professionId: 21, titleEn: 'Behavioral Health Education' },
    { professionId: 21, titleEn: 'Environmental Health Education' },
    { professionId: 21, titleEn: 'Global Health Education' },
    { professionId: 21, titleEn: 'Health Education in the Mass Media' },
    { professionId: 21, titleEn: 'Nutrition Health Education' },
    { professionId: 21, titleEn: 'Occupational Health Education & Safety' },
    { professionId: 21, titleEn: 'Patient Health Education' },
    { professionId: 21, titleEn: 'Preventative Health Education' },
    { professionId: 21, titleEn: 'School Health Education' },
];
const PublicAndCommunityHealthSPECIALTIES = [
    { professionId: 22, titleEn: 'Health Promotion' },
    { professionId: 22, titleEn: 'Behavioral Public Health' },
    { professionId: 22, titleEn: 'Biostatistics' },
    { professionId: 22, titleEn: 'Community Health' },
    { professionId: 22, titleEn: 'Dental Public Health' },
    { professionId: 22, titleEn: 'Environmental Public Health' },
    { professionId: 22, titleEn: 'Epidemiology' },
    { professionId: 22, titleEn: 'Global & International Public Health' },
    { professionId: 22, titleEn: 'Infection Control' },
    { professionId: 22, titleEn: 'Maternal & Child Public Health' },
    { professionId: 22, titleEn: 'Nutrition Public Health' },
    { professionId: 22, titleEn: 'Occupational Safety and Health' },
    { professionId: 22, titleEn: 'Public Health Laboratory Practice' },
    { professionId: 22, titleEn: 'Public Health Policy' },
    { professionId: 22, titleEn: 'Public Health Practice' },
    { professionId: 22, titleEn: 'Radiotherapy' },
    { professionId: 22, titleEn: 'Preventive Health' },
    { professionId: 22, titleEn: 'Biomedical Statistics' },
];
const AudiologySPECIALTIES = [
    { professionId: 23, titleEn: 'Auditory Electrophysiology' },
    { professionId: 23, titleEn: 'Auditory Processing Disorders' },
    { professionId: 23, titleEn: 'Aural Rehabilitation' },
    {
        professionId: 23,
        titleEn: 'Cochlear Implants & Other Implantable Devices',
    },
    { professionId: 23, titleEn: 'Hearing Aids & Assistive Listening Devices' },
    { professionId: 23, titleEn: 'Pediatric Audiology' },
];
const OptometrySPECIALTIES = [
    { professionId: 24, titleEn: 'Binocular Vision' },
    { professionId: 24, titleEn: 'Contact Lens' },
    { professionId: 24, titleEn: 'Cornea' },
    { professionId: 24, titleEn: 'Cornea & Clinical Contact Lens' },
    { professionId: 24, titleEn: 'Electrophysiology of Vision' },
    { professionId: 24, titleEn: 'General Optometry' },
    { professionId: 24, titleEn: 'Geriatric Optometry' },
    { professionId: 24, titleEn: 'Low Vision' },
    { professionId: 24, titleEn: 'Occupational Vision' },
    { professionId: 24, titleEn: 'Ophthalmology Technology' },
    { professionId: 24, titleEn: 'Orthoptic' },
    { professionId: 24, titleEn: 'Pediatrics Optometry' },
    { professionId: 24, titleEn: 'Primary Eye Care' },
    { professionId: 24, titleEn: 'Sport Vision' },
    { professionId: 24, titleEn: 'Vision Therapy' },
];
const PodiatrySPECIALTIES = [
    { professionId: 25, titleEn: 'Foot & Ankle Surgery' },
    { professionId: 25, titleEn: 'Foot Surgery' },
    { professionId: 25, titleEn: 'General Podiatric Practice' },
    { professionId: 25, titleEn: 'Podiatric Radiology' },
    { professionId: 25, titleEn: 'Podiatric Sports Medicine' },
];
const SpeechLanguagePathologySPECIALTIES = [
    { professionId: 26, titleEn: 'Alternative Communication Systems' },
    { professionId: 26, titleEn: 'Language Disorders' },
    { professionId: 26, titleEn: 'Neurogenic Communication Disorders' },
    { professionId: 26, titleEn: 'Speech Disorders' },
    { professionId: 26, titleEn: 'Stuttering' },
    { professionId: 26, titleEn: 'Swallowing Disorders' },
    { professionId: 26, titleEn: 'Voice Disorders' },
];
const MedicalEducationSPECIALTIES = [
    { professionId: 27, titleEn: 'Medical Education' },
];
const CardiacPerfusionSPECIALTIES = [
    { professionId: 28, titleEn: 'Cardiac Perfusion' },
];
const NonMedicalSPECIALTIES = [{ professionId: 29, titleEn: 'Non-Medical' }];
exports.SPECIALTIES = [
    ...StudentInternSPECIALTIES,
    ...ResidentFellowSPECIALTIES,
    ...PhysicianSPECIALTIES,
    ...DentistrySPECIALTIES,
    ...DentalTechnologyAndAssistantsSPECIALTIES,
    ...ClinicalPharmacySPECIALTIES,
    ...PharmacySPECIALTIES,
    ...NursingAndMidwiferySPECIALTIES,
    ...NutritionSPECIALTIES,
    ...LaboratoryScienceSPECIALTIES,
    ...ScienceAssistantSPECIALTIES,
    ...RadiologicalSPECIALTIES,
    ...MedicalTechnologySPECIALTIES,
    ...PhysiotherapySPECIALTIES,
    ...RespiratoryTherapySPECIALTIES,
    ...OccupationalTherapySPECIALTIES,
    ...SocialServiceSPECIALTIES,
    ...EmergencyMedicalServicesSPECIALTIES,
    ...HealthcareHospitalSPECIALTIES,
    ...HealthInformaticsSPECIALTIES,
    ...HealthEducationSPECIALTIES,
    ...PublicAndCommunityHealthSPECIALTIES,
    ...AudiologySPECIALTIES,
    ...OptometrySPECIALTIES,
    ...PodiatrySPECIALTIES,
    ...SpeechLanguagePathologySPECIALTIES,
    ...MedicalEducationSPECIALTIES,
    ...CardiacPerfusionSPECIALTIES,
    ...NonMedicalSPECIALTIES,
    ...PsychologySPECIALTIES,
];
